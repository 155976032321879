/*.globaliconstyles {*/
/*    color: #1de9b6;*/
/*}*/

/*.globaliconstyles:hover,*/
/*.globaliconstyles:visited {*/
/*    color: #1de9b6;*/
/*  text-decoration: none;*/
/*}*/

/*.globaliconstylesactive {*/
/*    !*color: #ff4864;*!*/
/*    color: #1de9b6;*/
/*}*/


/*.anonglobaliconstyles {*/
/*    color: #1de9b6;*/
/*}*/

/*.anonglobaliconstyles:hover,*/
/*.anonglobaliconstyles:visited {*/
/*    color: #1de9b6;*/
/*  text-decoration: none;*/
/*}*/

/*.anonglobaliconstylessactive {*/
/*    !*color: #ff4864;*!*/
/*    color: #1de9b6;*/
/*}*/
/*!*.globaliconstyles::selection {*!*/
/*!*    color: #ff4864;*!*/
/*!*}*!*/
