body {
    margin: 0;
    padding: 0;
    padding-top: 3em;
    font-family: Assistant, sans-serif;
    font-size: 14px;
            text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*@font-face {*/
/*  font-family: 'Assistant';*/
/*  src: local('Assistant'), local('Assistant-Regular'),*/
/*       url('/static/fonts/assistant-v4-latin-regular.woff2') format('woff2'),*/
/*       url('/static/fonts/assistant-v4-latin-regular.woff') format('woff'); !* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ *!*/
/*  font-weight: 400;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/


@font-face {
  font-family: 'Assistant';
  src: local('Assistant'), local('Assistant-Regular'),
       url('./Theme/fonts/assistant-v4-latin-regular.woff2') format('woff2'),
       url('./Theme/fonts/assistant-v4-latin-regular.woff') format('woff');
  /*font-weight: 400;*/
  /*font-style: normal;*/
  font-display: swap;
}


/*@font-face {*/
/*    font-family: 'Assistant';*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    src: local('Assistant'), local('Assistant-Regular'),*/
/*    url('./Theme/fonts/assistant-v4-latin-regular.woff2'),*/
/*    url('./Theme/fonts/assistant-v4-latin-regular.woff') format('woff'); !* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ *!*/
/*    !*font-family: 'Assistant-Bold';*!*/
/*    !*font-style: normal;*!*/
/*    !*font-weight: 700;*!*/
/*    !*src: local('Assistant Bold'), local('Assistant-Bold'),*!*/
/*    !*     url('./Theme/fonts/assistant-v4-latin-700.woff2') format('woff2'), !* Chrome 26+, Opera 23+, Firefox 39+ *!*!*/
/*    !*     url('./Theme/fonts/assistant-v4-latin-700.woff') format('woff'); !* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ *!*!*/
/*}*/


select.form-control,
textarea.form-control,
input.form-control {
    font-size: 16px;
}

input[type=file] {
    width: 100%;
}
