.QPilotStyles ol {
    padding-left: 0.75em;
    margin: 0;
}

.QPilotStyles ul {
    padding-left: 0.75em;
    margin: 0;
}

.QPilotStyles li {
    padding-left: 0.75em;
    margin: 0;
    margin-left: 0.25em;
}

.QPilotStyles a {
    color: #b61de9;
    font-weight: bold;
}

.QPilotStyles p {
    margin-block-start: 6px;
    margin-block-end: 6px;
}